// import { Within } from "@theme-toggles/react";
import "@theme-toggles/react/css/Within.css";
import { Link } from "react-router-dom";
// import useDarkMode from '../../hooks/useDarkMode';
import DarkModeToggle from "./DarkModeToggle";


const Navbar = () => {

    // const [darkTheme, setDarkTheme] = useDarkMode();


    return (
        <nav className="fixed bg-white dark:bg-dark  dark:border-lead500 border-b border-lead100  font-primary top-0 z-[100] mx-0 mt-0 w-full" data-aos="fade-down" data-aos-delay="200">
            <div className="relative  mx-auto px-3 flex h-[73px] max-w-8xl items-center justify-between  pb-4 pt-4 duration-300  motion-reduce:transition-none ">
                <Link to="/" className="ml-4 flex items-center gap-2">
                    <span className="font-amaranth text-brand font-bold md:text-3xl text-2xl">TakeUforward</span>
                </Link>

                <div className="ml-auto mr-4 flex gap-1 items-center">
                    {/* {token ? (
                            <UserMenu
                                UserAvatar={profile?.profileImage}
                                name={profile?.name}
                                username={cookieUsername}
                                align='right'
                            />
                        ) : ( */}
                    {/* <div className="mr-2 flex justify-center items-center px-2 rounded-lg border-brand_10 bg-[#FDEDEA] dark:bg-[#ee4c2b39] dark:border-[#ee4c2b88] border-2">
                        <Within toggled={darkTheme} toggle={setDarkTheme} duration={750} />
                    </div> */}
                    <DarkModeToggle />
                    {/* )} */}
                </div>
            </div>
        </nav>
    )
}
export default Navbar