import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import taPortalReducer  from "../slices/taPortalSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    taPortal: taPortalReducer,
});

export default rootReducer;
