import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getALlTas } from '../../services/operations/taPortal';
import TaTable from './TaTable';
import { useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { isObjectEmpty, transformTAData } from '../../utils/Utils';

const AllTAs = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { signinData } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getALlTas())
    }, [dispatch])


    const { allTas, allTasDate } = useSelector((state) => state.taPortal)

    useEffect(() => {
        if (signinData?.role === 'Intern') {

            navigate('/admin')
        }
    }, [navigate, signinData?.role])

    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });

    const handleValueChange = (newValue) => {
        // console.log("newValue:", newValue);
        dispatch(getALlTas(newValue?.startDate, newValue?.endDate))
        setValue(newValue);
    }

    //    console.log(allTasDate)

    const data = transformTAData(allTasDate)


    // console.log(data)

    // [
    //     {
    //         "email": "iamsiddhantmeshram@gmail.com",
    //         "data": [
    //             {
    //                 "date": "2024-04-17",
    //                 "total_closed": 0,
    //                 "closedToday": 0
    //             },
    //             {
    //                 "date": "2024-04-18",
    //                 "total_closed": 0,
    //                 "closedToday": 0
    //             }
    //         ]
    //     },
    //     {
    //         "email": "imvengeance9@gmail.com",
    //         "data": [
    //             {
    //                 "date": "2024-04-17",
    //                 "total_closed": 0,
    //                 "closedToday": 0
    //             },
    //             {
    //                 "date": "2024-04-18",
    //                 "total_closed": 0,
    //                 "closedToday": 0
    //             }
    //         ]
    //     },
    //     {
    //         "email": "test@gmail.com",
    //         "data": [
    //             {
    //                 "date": "2024-04-17",
    //                 "total_closed": 1,
    //                 "closedToday": 1
    //             },
    //             {
    //                 "date": "2024-04-18",
    //                 "total_closed": 1,
    //                 "closedToday": 0
    //             }
    //         ]
    //     }
    // ]
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='mb-4'>
                            <Datepicker
                                value={value}
                                primaryColor={"red"}
                                onChange={handleValueChange}
                                showShortcuts={true}
                            />
                        </div>
                        {value?.startDate && value?.endDate ? (
                            <>
                                {!isObjectEmpty(allTasDate) ? (
                                    <>
                                        {data && data.length > 0 && (
                                            <>
                                                {data?.map((item) => (
                                                    <div className='mt-10'>
                                                        <p className='mb-4'>Email: {item?.email}</p>
                                                        {item?.data && item?.data.length > 0 && (
                                                            <TaTable type="TADate" topics={item?.data} />
                                                        )}
                                                    </div>

                                                ))}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <p>
                                        Sorry, No data between {value?.startDate} and {value?.endDate}
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                {allTas && allTas.length > 0 && (
                                    <TaTable topics={allTas} />
                                )}
                            </>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AllTAs