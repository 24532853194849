/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Markdowneditor from '../../components/MarkdownEditor/MarkdownEditor';
import { addReply, getOngoing } from '../../services/operations/taPortal';
import { convertAndSortReplies, formatDateString, getTimestamp, sortRepliesAndConvertToIST, sortRepliesByTime } from '../../utils/Utils';
import { io } from 'socket.io-client';
import toast from 'react-hot-toast';

const Discussion = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [replies, setReplies] = useState([]);
    const params = useParams()
    const { ongoing } = useSelector((state) => state.taPortal)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getOngoing());

        // Listen for 'newReply' event from the backend
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);

        socket.on("messageReply", function (msg) {
            toast.success("New Reply")
            if (msg?.pid === params?.pid) {
                setReplies(prevReplies => [...prevReplies, msg.reply]);
            }
        });

        // Clean up function to remove the event listener when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, [dispatch, params?.pid]);



    const { handleSubmit, formState: { errors, isSubmitted }, register, setValue } = useForm();

    const onEditorContentChanged = (content) => {
        setValue('editorHtmlValue', content.html);
    };

    let filteredData = ongoing && ongoing?.length > 0 && ongoing?.filter((data) => data?.cid === params?.cid && data.pid === params?.pid)

    filteredData = sortRepliesByTime(filteredData)

    // console.log(ongoing)
    const onSubmit = (data) => {
        dispatch(addReply(data.editorHtmlValue, params?.cid, params?.pid))
    }

    // console.log(filteredData)

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        <div className='flex flex-col gap-y-4'>
                            {filteredData && filteredData?.length > 0 && filteredData?.map((data) => (
                                <>
                                    <div className='mb-4 flex flex-col'>
                                        <span className='text-xl font-bold mb-4'>Main Doubt:</span>
                                        <div className='p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800'>
                                            <div className='flex flex-col'>
                                                <div className='flex flex-row items-center gap-x-4'>

                                                    <p className='text-xs font-normal text-gray-500'>{getTimestamp(data?.comment_timestamp)}</p>
                                                </div>
                                                <p><div dangerouslySetInnerHTML={{ __html: data?.comment_description }} /></p>
                                            </div>
                                        </div>
                                    </div>
                                    {replies && replies?.length > 0 && (
                                        <>
                                            <h1>New Reply:</h1>
                                            {replies?.map((item) => (
                                                <>
                                                    {item?.r_id && (
                                                        <div className='p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800 shadow-sm shadow-orange-200'>
                                                            <div className='flex flex-col'>
                                                                <div className='flex flex-row items-center gap-x-4'>

                                                                    <p className='text-xs font-normal text-gray-500'>{getTimestamp(item?.time)}</p>
                                                                </div>
                                                                <p><div dangerouslySetInnerHTML={{ __html: item?.r_desc }} /></p>
                                                            </div>
                                                        </div>
                                                    )}

                                                </>
                                            ))}
                                        </>
                                    )}
                                    <span className='text-xl font-bold mb-2 mt-4'>All Replies:</span>
                                    {data && data?.replies?.length > 0 ? (
                                        <>

                                            {data?.replies?.map((item) => (
                                                <>
                                                    {item?.r_id ? (
                                                        <div className='p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800'>
                                                            <div className='flex flex-col'>
                                                                <div className='flex flex-row items-center gap-x-4'>

                                                                    <p className='text-xs font-normal text-gray-500'>{getTimestamp(item?.time)}</p>
                                                                    {/* <p className='text-xs font-normal text-gray-500'>PostedBy: {item?.r_email}</p> */}
                                                                </div>
                                                                <p><div dangerouslySetInnerHTML={{ __html: item?.r_desc }} /></p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <span>No reply to this comment</span>
                                                    )}

                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <span>No Replies</span>
                                    )}

                                </>
                            ))}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6 mt-4">
                            <Markdowneditor
                                options="Discussion"
                                {...register('editorHtmlValue', { required: 'Content should have more than 10 characters.', minLength: { value: 10, message: 'Content should have more than 10 characters.' } })}
                                onChange={onEditorContentChanged}
                                isSubmitted={isSubmitted}
                                height="discussion"
                            />
                            {errors.editorHtmlValue && <p className="text-red-500">{errors.editorHtmlValue.message}</p>}
                            <div className="flex justify-between items-center">
                                <button
                                    type="submit"
                                    className={`w-fit ml-auto  mt-10 rounded-lg relative inline-flex group items-center justify-center px-3.5 py-2 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-brand active:shadow-none shadow-lg bg-gradient-to-tr from-brand to-brand_50 hover:from-brand_50 hover:to-brand_40  border-brand_60 text-white`}
                                >
                                    Post
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Discussion