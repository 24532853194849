import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTopicsStats } from '../../services/operations/taPortal';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import TopicStatsTable from './TopicStatsTable';

const TopicStats = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { signinData } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTopicsStats())
    }, [dispatch])

    const { topicStats } = useSelector((state) => state.taPortal)

    useEffect(() => {
        if (signinData?.role === 'Intern') {

            navigate('/admin')
        }
    }, [navigate, signinData?.role])
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {topicStats && topicStats.length > 0 && (
                            <TopicStatsTable topics={topicStats} />
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default TopicStats