const BASE_URL = process.env.REACT_APP_BACKEND_URL


export const authEndpoints = {
    LOGIN_IN: BASE_URL + '/auth/login',
    LOGOUT_IN: BASE_URL + '/auth/logout',
}

export const otherEndpoints = {
    MY_STATS: BASE_URL  + '/myStats',
    ASSIGNED_TOPICS: BASE_URL  + '/assignedTopics',
    TOPICS: BASE_URL  + '/topics',
    UNASSIGNED_TOPICS: BASE_URL  + '/unassignedTopics',
    TOPICS_COUNT: BASE_URL  + '/countByTopic',
    TOPICS_STATS: BASE_URL  + '/topicStats',
    ONGOING: BASE_URL  + '/ongoing',
    ACCEPTED: BASE_URL  + '/accepted',
    UN_ANSWERED: BASE_URL  + '/unanswered/',
    ASSIGN_COMMENT: BASE_URL  + '/assignComment',
    REPLY: BASE_URL  + '/reply/',
    ALL_TAS: BASE_URL  + `/admin/userStats`,
    ADD_USER: BASE_URL  + '/admin/setUser',
    DLETE_REPLY: BASE_URL  + '/delete/reply/',
}