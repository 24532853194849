import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getMyStats } from '../../services/operations/taPortal';
const MyStats = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const { myStats } = useSelector((state) => state.taPortal)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMyStats())
    }, [dispatch])
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='grid md:grid-cols-3 grid-cols-2 gap-10'>
                            <div class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Closed</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >{myStats?.closed}</div>
                                </div>
                            </div>
                            <div class="rounded-xl shadow-md p-6 bg-brand_50">
                                <div class="font-semibold mb-1 md:text-lg text-md text-white" >Ongoing</div>
                                <div className='flex justify-between items-center'>
                                    <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >{myStats?.ongoing}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default MyStats