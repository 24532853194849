import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { assigenComment, getUnanswered } from '../../services/operations/taPortal';
import { useNavigate, useParams } from 'react-router-dom';
import { getTimestamp } from '../../utils/Utils';

const UnansweredTopic = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { unAnswered } = useSelector((state) => state.taPortal)
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(getUnanswered(params?.topic))
    }, [dispatch, params?.topic])


    const handleAnswerComment = (e, cId, pId) => {
        e.stopPropagation();
        dispatch(assigenComment(cId, pId, params?.topic, navigate))
    }

    // console.log(latestReply)
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex flex-col gap-y-8'>
                            {unAnswered && unAnswered?.length > 0 ? unAnswered?.map((data) => (
                                <>
                                    <div className='p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800'>
                                        <div className='flex flex-col w-full'>
                                            <div className='flex justify-between w-full'>
                                                <p className='text-xs font-normal text-gray-500'>{getTimestamp(data?.comment_timestamp)}</p>
                                                <button className='btn' onClick={(e) => handleAnswerComment(e, data?.cid, data?.pid)}>Answer</button>
                                            </div>
                                            <p><div dangerouslySetInnerHTML={{ __html: data?.description }} /></p>
                                        </div>
                                    </div>

                                </>
                            )) : (
                                <p className='flex justify-center items-center text-4xl'>Nothing to Answer</p>
                            )}

                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default UnansweredTopic