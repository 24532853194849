import React, { useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { ChevronsRight } from 'lucide-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Admin = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { signinData } = useSelector((state) => state.auth)
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {signinData?.role === 'Intern' && (
                            <div className='grid md:grid-cols-3 grid-cols-2 gap-10'>

                                <Link to="/ongoing" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >Ongoing</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Page</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                                <Link to="/unanswered" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >Unanswered</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Page</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                                <Link to="/my-stats" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >My Stats</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Page</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                            </div>
                        )}
                        {signinData?.role === 'Admin' && (
                            <div className='grid md:grid-cols-3 grid-cols-2 gap-10'>

                                <Link to="/add-user" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >Add</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >User</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                                <Link to="/all-ta" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >All</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >TA's</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                                <Link to="/topics-stats" class="rounded-xl shadow-md p-6 bg-brand_50">
                                    <div class="font-semibold mb-1 md:text-lg text-md text-white" >Topics</div>
                                    <div className='flex justify-between items-center'>
                                        <div class="font-semibold md:text-5xl text-4xl tracking-tight text-[#f5f5f5]" >Stats</div>
                                        <ChevronsRight size={40} className='stroke-white' />
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Admin