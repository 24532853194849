import React from 'react'

const TaTableItem = (props) => {
    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {props?.num}.
                    </div>
                </td>
                {props?.type === 'TADate' ? (
                    <>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.date}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.closedToday}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.total_closed}
                            </div>
                        </td>
                    </>
                ) : (
                    <>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.email}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.name}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.ongoing}
                            </div>
                        </td>
                        <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                            <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-start items-start">
                                {props?.closed}
                            </div>
                        </td>
                    </>
                )}

            </tr>
        </tbody>
    )
}

export default TaTableItem