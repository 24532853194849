import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getAccepted } from '../../services/operations/taPortal';
import { getTimestamp } from '../../utils/Utils';
import toast from 'react-hot-toast';
import { Share } from 'lucide-react';

const Accepted = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { accepted } = useSelector((state) => state.taPortal)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAccepted())
    }, [dispatch])

    const handleCommentShare = (e, cid, pid, categoryId, subcategoryId) => {
        e.stopPropagation();
        navigator.clipboard.writeText(`https://takeuforwardbuild.in/plus/${categoryId}/${subcategoryId}/${pid}?doubt=${cid}`)
        toast.success("Copied to clipboard")
    }
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {accepted && accepted?.length > 0 ? accepted?.map((data) => (
                            <>
                                <div className={`p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800 `}>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex justify-between w-full'>
                                            <p className='text-xs font-normal text-gray-500'>{getTimestamp(data?.comment_timestamp)}</p>
                                            <div className='flex flex-row space-x-4 justify-center items-center'>
                                                <button onClick={(e) => handleCommentShare(e, data.cid, data.pid, data.category_id, data.subcategory_id)} className='btn  w-fit'><Share size={15} className='mr-1' />Share</button>
                                            </div>
                                        </div>
                                        <p><div dangerouslySetInnerHTML={{ __html: data?.comment_description }} /></p>
                                    </div>

                                </div>
                            </>
                        )) : (
                            <span className='flex justify-center items-center text-4xl'>None of comments are Accepted</span>
                        )}
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Accepted