import Login from './pages/Login/Login'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './utils/ProtectedRoute'
import NotFound from './pages/NotFound/NotFound'
import Admin from './pages/Admin/Admin'
import Ongoing from './pages/Ongoing/Ongoing'
import Unanswered from './pages/Unanswered/Unanswered'
import MyStats from './pages/MyStats/MyStats'
import Accepted from './pages/Accepted/Accepted'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getAllTopics } from './services/operations/taPortal'
import UnansweredTopic from './pages/Unanswered/UnansweredTopic'
import Discussion from './pages/Ongoing/Discussion'
import AddUser from './pages/AddUser/AddUser'
import AllTAs from './pages/AllTAs/AllTAs'
import TopicStats from './pages/TopicStats/TopicStats'
const App = () => {

    const dispatch = useDispatch()

    const { token } = useSelector((state) => state.auth)

    useEffect(() => {
        if (token) {
            dispatch(getAllTopics())
        }
    }, [dispatch, token])

    return (
        <div>
            <Routes>
                <Route
                    path='/'
                    element={
                        <Login />
                    }
                />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path='/admin'
                        element={
                            <Admin />
                        }
                    />
                    <Route
                        path='/ongoing'
                        element={
                            <Ongoing />
                        }
                    />
                    <Route
                        path='/ongoing/:cid/:pid'
                        element={
                            <Discussion />
                        }
                    />
                    <Route
                        path='/unanswered'
                        element={
                            <Unanswered />
                        }
                    />
                    <Route
                        path='/unanswered/:topic'
                        element={
                            <UnansweredTopic />
                        }
                    />
                    <Route
                        path='/accepted'
                        element={
                            <Accepted />
                        }
                    />
                    <Route
                        path='/my-stats'
                        element={
                            <MyStats />
                        }
                    />
                    <Route
                        path='/add-user'
                        element={
                            <AddUser />
                        }
                    />
                    <Route
                        path='/all-ta'
                        element={
                            <AllTAs />
                        }
                    />
                    <Route
                        path='/topics-stats'
                        element={
                            <TopicStats />
                        }
                    />

                </Route>
                <Route
                    path='*'
                    element={
                        <NotFound />
                    }
                />
            </Routes>
        </div>
    )
}

export default App