import toast from "react-hot-toast"
import { otherEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import { setAccepted, setAllTas, setAllTasDate, setAssignedTopics, setMyStats, setOngoing, setTopicStats, setTopics, setUnAnswered, setUnAssignedTopics } from "../../slices/taPortalSlice"


const { MY_STATS, ASSIGNED_TOPICS, TOPICS, TOPICS_COUNT, ONGOING, UN_ANSWERED, ASSIGN_COMMENT, REPLY, ALL_TAS, UNASSIGNED_TOPICS, ADD_USER, DELETE_REPLY, TOPICS_STATS, ACCEPTED } = otherEndpoints

export function getALlTas(startDate, endDate) {
    return async (dispatch) => {
        try {
            if (startDate && endDate) {
                const response = await apiConnector("GET", ALL_TAS + `?startDate=${startDate}&endDate=${endDate}`)
                dispatch(setAllTasDate(response.data))
            } else {
                const response = await apiConnector("GET", ALL_TAS)
                dispatch(setAllTas(response.data))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getMyStats() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", MY_STATS)
            dispatch(setMyStats(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getUnanswered(topic) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", UN_ANSWERED + `${topic}`)
            dispatch(setUnAnswered(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getOngoing() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", ONGOING)
            dispatch(setOngoing(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAccepted() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", ACCEPTED)
            dispatch(setAccepted(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAllTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOPICS)
            dispatch(setTopics(response.data))
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAllUnAssignedTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", UNASSIGNED_TOPICS)
            const transformedArray = response?.data?.map(obj => {
                return {
                    value: obj.id,
                    label: obj.name
                };
            });

            dispatch(setUnAssignedTopics(transformedArray))
            // console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAssignedTopics() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", ASSIGNED_TOPICS)
            dispatch(setAssignedTopics(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getCountTopic() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOPICS_COUNT)
            console.log(response.data)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getTopicsStats() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOPICS_STATS)
            dispatch(setTopicStats(response.data))
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function addReply(description, cid, pid) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", REPLY + `${pid}/${cid}`, { description })
            if (response.data.reply) {
                dispatch(getOngoing())
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function assigenComment(cid, pid, topic, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ASSIGN_COMMENT, { pid, cid })
            // console.log(response.data)
            if (response.data.success) {
                toast.success(response.data.message)
                dispatch(getUnanswered(topic))
                navigate(`/ongoing/${cid}/${pid}`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function setUser(name, password, email, role, assignedTopics) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_USER, { name, password, email, role, assignedTopics })
            // console.log(response.data)
            if (response.data.success) {
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error) {
                console.log(error)
                toast.error(error.response.data.message)
            }
        }
    }
}

export function deleteReply(rid, cid, pid) {
    return async (dispatch) => {
        try {
            const response = await apiConnector('DELETE', DELETE_REPLY + `${pid}/${cid}/${rid}`)

            console.log(response.data)

        } catch (error) {
            console.log("LIKE REPLY ERROR............", error)
        }
    }
}