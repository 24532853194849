import toast from "react-hot-toast"
import { setSignInData, setToken } from "../../slices/authSlice"
import { deleteCookie, setObjectInCookie } from "../../utils/Utils"
import { authEndpoints } from "../api"
import { apiConnector } from "../apiConnector"


const { LOGIN_IN, LOGOUT_IN } = authEndpoints

export function login(email, password, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGIN_IN, { email, password })

            if (response.data.success && response.data.userData) {
                setObjectInCookie('userDataTA', response.data.userData, 7)
                setSignInData(response.data.userData)
                setToken(response.data.token)
                toast.success("Login Successful")
                window.location.reload()
                navigate(`/admin`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}


export function logout(navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGOUT_IN)

            if (response.data.success) {
                deleteCookie('userDataTA')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("tuf")
                toast.success("Logout Successful")
                window.location.reload()
                navigate(`/`)
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Session Expired")
                deleteCookie('userDataTA')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("tuf")
                window.location.reload()
            }
        }
    }
}