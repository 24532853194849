import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getOngoing } from '../../services/operations/taPortal';
import { getTimestamp } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Share } from 'lucide-react';
import toast from 'react-hot-toast';
// import { io } from 'socket.io-client';
const Ongoing = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { ongoing } = useSelector((state) => state.taPortal)
    const [replies, setReplies] = useState([]);
    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getOngoing());

        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);

        socket.on("messageReply", function (msg) {
            let timestamp = new Date(msg.reply.time).toISOString().replace('T', ' ').replace('Z', '000000');
            msg.reply.time = timestamp;
            setReplies(prevReplies => [...prevReplies, msg.reply]);
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch]);

    const handleAnswerReply = (e, cId, pId, latest_reply) => {
        navigate(`/ongoing/${cId}/${pId}`)
    }

    const handleCommentShare = (e, cid, pid, categoryId, subcategoryId) => {
        e.stopPropagation();
        navigator.clipboard.writeText(`https://takeuforwardbuild.in/plus/${categoryId}/${subcategoryId}/${pid}?doubt=${cid}`)
        toast.success("Copied to clipboard")
    }
    // console.log(ongoing)
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex flex-col gap-y-8'>
                            {ongoing && ongoing?.length > 0 ? ongoing?.map((data) => {
                                // Check if there are new replies for this post
                                const newReplies = replies.filter(reply => reply.cid === data.cid);
                                const numNewReplies = newReplies.length;
                                return (
                                    <div className={`p-4 bg-white flex justify-between isolate  dark:bg-dark_50 rounded-lg border border-zinc-300 dark:border-zinc-800 ${numNewReplies > 0 && "shadow-sm shadow-orange-200"}`}>
                                        <div className='flex flex-col w-full'>
                                            <div className='flex justify-between w-full'>
                                                <p className='text-xs font-normal text-gray-500'>{getTimestamp(data?.comment_timestamp)}</p>
                                                <div className='flex flex-row space-x-4 justify-center items-center'>
                                                    <button onClick={(e) => handleAnswerReply(e, data?.cid, data?.pid, data?.latest_reply)} className='btn'>View</button>
                                                    {numNewReplies > 0 && (
                                                        <span className='rounded-full bg-brand text-center w-6 h-6'>{numNewReplies}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <p><div dangerouslySetInnerHTML={{ __html: data?.comment_description }} /></p>
                                            <button onClick={(e) => handleCommentShare(e, data.cid, data.pid, data.category_id, data.subcategory_id)} className='btn mt-4 w-fit'><Share size={15} className='mr-1' />Share</button>
                                        </div>
                                    </div>
                                );
                            }) : (
                                <span className='flex justify-center items-center text-4xl'>Nothing is going on</span>
                            )}
                        </div>
                    </div>
                </main>
            </div >
        </div >
    )
}

export default Ongoing