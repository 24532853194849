import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { getAllUnAssignedTopics, setUser } from '../../services/operations/taPortal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({ mode: 'all' });

    const { unAssignedTopics } = useSelector((state) => state.taPortal)
    const { signinData } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isPasswordValid = (value) => {
        return value.length >= 6 || 'Password must be at least 6 characters';
    };

    const handleOnSubmit = (data) => {

        const { email, name, password, role } = data;
        const outputArray = data?.topics?.map(item => item.value);
        dispatch(setUser(name, password, email, role, outputArray))
        setValue('name', '');
        setValue('email', '');
        setValue('password', '');
        setValue('role', '');
        setValue('topics', '');
    }

    useEffect(() => {
        dispatch(getAllUnAssignedTopics())
    }, [dispatch])

    useEffect(() => {
        if (signinData?.role === 'Intern') {

            navigate('/admin')
        }
    }, [navigate, signinData?.role])



    // console.log(unAssignedTopics)
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <form onSubmit={handleSubmit(handleOnSubmit)} className='max-w-xl mx-auto'>
                            <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="text"
                                                name="email"
                                                className="form-input w-full text-gray-300"
                                                placeholder="Email"
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Email is required',
                                        }}
                                    />
                                    {errors.email && <p className="text-red-600 text-sm italic">{errors.email.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-input w-full text-gray-300"
                                                placeholder="name"
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'name is required',
                                        }}
                                    />
                                    {errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-input w-full text-gray-300"
                                                placeholder="Password (at least 10 characters)"
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Password is required',
                                            validate: {
                                                isPasswordValid,
                                            },
                                        }}
                                    />
                                    {errors.password && <p className="text-red-600 text-sm italic">{errors.password.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                    <Controller
                                        name="topics"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <Select
                                                    isMulti
                                                    name="topics"
                                                    options={unAssignedTopics?.length > 0 ? unAssignedTopics : []}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-4">
                                <div className="w-full px-3">
                                    <Controller
                                        name="role"
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select w-full text-gray-300"
                                            >
                                                <option value="">Select Role...</option>
                                                <option value="Admin">Admin</option>
                                                <option value="Intern">Intern</option>
                                            </select>
                                        )}
                                        rules={{
                                            required: 'Role is required',
                                        }}
                                    />
                                    {errors.role && <p className="text-red-600 text-sm italic">{errors.role.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mt-6">
                                <div className="w-full px-3">
                                    <button type="submit" className="btn-brand">Add User</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddUser