import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myStats: [],
    topics: [],
    unAnswered: [],
    ongoing: [],
    accepted: [],
    assignedTopics: [],
    countByTopic: [],
    latestReply: [],
    allTas: [],
    allTasDate: {},
    topicStats: [],
    unAssignedTopics: [],
    loading: false,
};

const taPortalSlice = createSlice({
    name: "taPortal",
    initialState: initialState,
    reducers: {
        setMyStats(state, value) {
            state.myStats = value.payload;
        },
        setTopics(state, value) {
            state.topics = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setUnAnswered(state, value) {
            state.unAnswered = value.payload;
        },
        setOngoing(state, value) {
            state.ongoing = value.payload;
        },
        setAccepted(state, value) {
            state.accepted = value.payload;
        },
        setAssignedTopics(state, value) {
            state.assignedTopics = value.payload;
        },
        setUnAssignedTopics(state, value) {
            state.unAssignedTopics = value.payload;
        },
        setLatestReply(state, value) {
            state.latestReply = value.payload;
        },
        setAllTas(state, value) {
            state.allTas = value.payload;
        },
        setAllTasDate(state, value) {
            state.allTasDate = value.payload;
        },
        setTopicStats(state, value) {
            state.topicStats = value.payload;
        },
        setCountByTopic(state, value) {
            state.countByTopic = value.payload;
        }
    },
});

export const { setMyStats, setLoading, setTopics, setCountByTopic, setAssignedTopics, setAccepted, setOngoing, setUnAnswered, setLatestReply, setAllTas, setUnAssignedTopics, setAllTasDate, setTopicStats } = taPortalSlice.actions;

export default taPortalSlice.reducer;
