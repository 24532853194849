import React from 'react'
import TaTableItem from './TaTableItem'

const TaTable = ({ topics, type }) => {
    return (
        <div className="  font-primary  relative">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full  divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500  first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">No.</div>
                                </th>

                                {type === 'TADate' ? (
                                    <>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Date</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                            <div className="font-semibold flex items-start">Total Closed</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Closed Today</div>
                                        </th>
                                    </>
                                ) : (
                                    <>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Email</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 w-1/2 first:pl-10 last:pr-10 py-3 whitespace-nowrap">
                                            <div className="font-semibold flex items-start">Name</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Ongoing</div>
                                        </th>
                                        <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="font-semibold text-center">Closed</div>
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        {
                            topics?.map((data, index) => {
                                return (
                                    <TaTableItem
                                        num={index + 1}
                                        name={data?.name}
                                        email={data?.email}
                                        ongoing={data?.ongoing}
                                        closed={data?.closed}
                                        closedToday={data?.closedToday}
                                        total_closed={data?.total_closed}
                                        date={data?.date}
                                        type={type}
                                    />
                                )
                            })
                        }
                    </table>

                </div>
            </div>
        </div >
    )
}

export default TaTable