import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { getAssignedTopics, getUnanswered } from '../../services/operations/taPortal';
import UnansweredTable from './UnansweredTable';

const Unanswered = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { topics, assignedTopics } = useSelector((state) => state.taPortal)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAssignedTopics())
    }, [dispatch])

    const assignedTopicsList = topics.filter(topic => assignedTopics.includes(topic.id));

    // console.log(assignedTopicsList)
    // {
    //     "id": "sorting",
    //     "name": "Sorting ",
    //     "rank": 2
    // }
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex flex-col gap-y-10 text-zinc-800 dark:text-zinc-300'>
                            <div className='flex flex-col gap-y-4'>
                                <p className='text-3xl'>Assigned Topics</p>
                                <UnansweredTable topics={assignedTopicsList}/>
                                {/* {assignedTopicsList.map(topic => (
                                    <Link to={`/unanswered/${topic.id}`} className='px-6 py-4 font-medium text-2xl inline-flex items-center dark:text-zinc-300 dark:border-zinc-700 border rounded leading-5 shadow-sm transition duration-150 ease-in-out w-fit mt-4' key={topic.id}>{topic.name}</Link>
                                ))} */}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Unanswered;
